<!--
 * @Description:停车记录卡片组件
 * @Author: yanxiao
 * @Github: https://github.com/hzylyh
 * @Date: 2021-04-01 15:53:26
 * @LastEditors: LinFeng
-->
<template>
  <van-row class='card-main'>
    <div class="card-left">
      <van-row class="card-left-row">
        <span class="circle"
              style="background: #82e77a;"></span>
        <span class="card-date">{{cardData.entranceTime}}</span>
      </van-row>
      <van-row class="card-left-row">
        <span class="circle"
              style=" background: #f56060;"></span>
        <span class="card-date">{{cardData.leaveTime}}</span>
      </van-row>
      <van-row class="card-left-row">
        <img class="card-left-ico"
             :src="require('@/assets/homePage/parkingRecord/locationIco.png')">
        <span class="card-date">{{cardData.parkName}}</span>
      </van-row>
      <van-row class="card-left-row">
        <img class="card-left-ico"
             :src="require('@/assets/homePage/parkingRecord/dateIco.png')">
        <span class="card-date">{{cardData.parkDuration}}</span>
      </van-row>
    </div>
    <div class="card-right">
      <van-row v-if="cardData.chargeState == '在停中'"
               class="chargeState">{{cardData.chargeState}}</van-row>
      <van-row v-else
               class="chargeState yuan">{{cardData.receivedMoneyAmount}}元</van-row>
      <!-- <van-row class="chargeType">（{{cardData.billingRuleDefId}}）</van-row> -->
      <van-row @click="tobillRuleDetail">
        <van-row class="card-right-img">
          <img :src="require('@/assets/homePage/parkingRecord/tichengguize.png')">
        </van-row>
        <van-row class="card-right-guize">
          收费规则
        </van-row>
      </van-row>
    </div>
  </van-row>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    cardData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    // 这里存放数据
    return {

      // billingRuleDefId: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 路由跳转到收费规则详情
    tobillRuleDetail () {
      // console.log('组件猝发时间', this.cardData)
      this.$emit('clickRule', this.cardData)
      // this.$router.push({ name: 'billRuleDetail', query: { billingRuleDefId: this.cardData.billingRuleDefId, page: 'parkingRecord' } })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.card-main {
  width: 100%;
  background: #ffffff;
  padding: 8px 0px;
}
.card-left {
  height: 100%;
  width: 75%;
  float: left;
  .card-left-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    font-size: 15px;
    font-weight: Regular;
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 10px 0 15px;
      border-radius: 50%;
    }
    .circle-red {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 10px 0;
      border-radius: 50%;
    }
    .card-date {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #666666;
    }
    .card-left-ico {
      width: 8px;
      height: 9px;
      margin: 0 10px 0 15px;
    }
  }
}
.card-right {
  height: 100%;
  width: 25%;
  float: right;
  .chargeState {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    color: #82e77a;
  }
  .yuan {
    color: #666666;
  }
  .chargeType {
    margin-top: 5px;
    text-align: center;
    font-size: 10px;
  }
  .card-right-img {
    margin-top: 13px;
    text-align: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .card-right-guize {
    margin-top: 2px;
    text-align: center;
    font-size: 10px;
    color: #19a9fc;
  }
}
</style>
